// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { styled } from "@mui/system";
import TextField from '@mui/material/TextField';
// import { useFormControl } from '@mui/material/FormControl';

export default function PriceInput({ text, value, max, onChange = () => {} }) {
  return (
    <Box 
         position="relative"
         component="form"
         sx={{
         display: 'flex',
         alignItems: 'center',
        '& > :not(style)': { m: 1 },
      }}
         noValidate
         autoComplete="off"
    >


      <TextField
          fullWidth
          focused
          max={max}
          min={0}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={text}
          id="fullWidth"
          label="BNB"
          type="number"
          // InputLabelProps={{
          //   shrink: true,
          // }}
        />


      
    </Box>
  );
}
