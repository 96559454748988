import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import {FaWallet} from 'react-icons/fa';

import { useAuthContext } from "../../providers/AuthProvider";


const ConnectButton = styled(Button)(({ theme }) => ({

  position: "fixed",
  right: 48,
  top: 48,

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const SmallScreenConnectButton = styled(Button)(({ theme }) => ({

  display: "none",
  marginTop: 34,
  marginBottom: 48,
  width: "95%",
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

export default function Connect({ responsive = true }) {
  const { address, loading, connect, disconnect } = useAuthContext();

  const walleticon = <FaWallet  style={{ marginLeft: "auto", marginRight: "5px"}} />;
  

  return responsive ? (
    <ConnectButton
      style={{ background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))" }}
      variant="contained"
      color="secondary"
      disabled={loading}
      onClick={() => (address ? disconnect() : connect())}
    > {walleticon} 
      {address ? "Disconnect" : "Connect"}
    </ConnectButton>
  ) : (
    <SmallScreenConnectButton
     style={{ background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))" }}
      variant="contained"
      color="secondary"
      disabled={loading}
      onClick={() => (address ? disconnect() : connect())}
    > {walleticon} 
      {address ? "Disconnect" : "Connect"}
    </SmallScreenConnectButton>
  );
}
