import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import FolderIcon from '@mui/icons-material/Folder';
// import RestoreIcon from '@mui/icons-material/Restore';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import Link from '@mui/material/Link';
import { FaFacebookMessenger } from 'react-icons/fa';



export default function LabelBottomNavigation() {

  const messanger = <FaFacebookMessenger size="large"/>;

  const [value, setValue] = React.useState('twitte');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation sx={{ width: 300 }}  style={{background: "none",  gap: 8 }} value={value} onChange={handleChange}>
    {/* <Link href="https://t.me/RoyalTree" underline="hover"> */}
    <BottomNavigationAction
     style={{border: "1px solid #41dff5",  borderRadius: 8,  color:"white"  }} 
        // label="Facebook"
        value="facebook"
        href="https://m.me/j/Aba38bvbAYXEyQDp/" 
        target="__blank"
        icon= { messanger }
      />
   {/* </Link> */}

 
      
      <BottomNavigationAction
        style={{border: "1px solid #41dff5", borderRadius: 8,  color:"white"  }} 
        // label="Twitter"
        value="twitter"
        href="https://twitter.com/Apexproadvance?t=zJCCOsPoFK-ZJPAqdfNBSA&s=09" 
        target="__blank"
        icon={<TwitterIcon fontSize="large" />}
      />
   
  
      <BottomNavigationAction
       style={{border: "1px solid #41dff5", borderRadius: 8,  color:"white"  }} 
        // label="Telegram"
        value="telegram"
        href="https://t.me/apexproadvance" 
        target="__blank"
        icon={<TelegramIcon  fontSize="large"/>}
      />

      {/* <BottomNavigationAction
        label="Discord"
        value="discord"
        icon={<TelegramIcon />}
      /> */}
      {/* <BottomNavigationAction label="Folder" value="folder" icon={<FolderIcon />} /> */}
    </BottomNavigation>
  );
}
