import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';

import Web3 from "web3";

import { useContractContext } from "../../providers/ContractProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import { useEffect, useState } from "react";
import { config } from "../../config";
import Address from './address'

import { styled } from "@mui/system";
import cardimage1Url from "../../assets/frame2.webp";

// const CardWrapper = styled(Card)({
//   background: "#022e6b",
//   marginBottom: 24,
// });

const useStyles = makeStyles({
  card: {
    // check index.css for  backgroundImage: `url(${process.env.PUBLIC_URL}/src/assets/frame2.webp)`,
    backgroundSize: 'cover',
    marginBottom: 26,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 100,
    backgroundImage: `url(${cardimage1Url})`,
    backgroundPositionX: "center",
    backgroundPositionY: "crnter",
    // boxShadow: "none",
  }

});



// const reflevelinfos = [
//   {
//     label: "1st Level",
//     value: walletBalance.info[0],
   
//   },
//   {
//     label: "2nd Level",
//     value: 0,
   
//   },
//   {
//     label: "3rd Level",
//     value: 0,
   
//   },
//   {
//     label: "4th Level",
//     value: 0,
   
//   },
//   {
//     label: "5th Level",
//     value: 0,
   
//   },
//   {
//     label: "6th Level",
//     value: 0,
    
//   },
//   {
//     label: "7th Level",
//     value: 0,
   
//   },
//   {
//     label: "8th Level",
//     value: 0,
    
//   },
//   {
//     label: "9th Level",
//     value: 0,
   
//   },
//   {
//     label: "10th Level",
//     value: 0,
   
//   },

// ];

export default function ReflevelsInfo() {

const classes = useStyles();


const { contract, wrongNetwork, getBnbBalance, fromWei, toWei, web3 } = useContractContext();
const { address, chainId } = useAuthContext();
const [contractBNB, setContractBNB] = useState(0);
const [walletBalance, setWalletBalance] = useState({
  
    reflevelinfo: 0,
  
});



const fetchWalletBalance = async () => {
    if (!web3 || wrongNetwork || !address) {
      setWalletBalance({
      
        reflevelinfo: 0,
       
      });
      return;
    }

    try {
        const [ info1 ]
      = await Promise.all([

       
        contract.methods
        .getUserDownlineCount(address)
        .call()

        // $("#lvl1").html(info[0]);

        .catch((err) => {
          console.error("downlinerefercount", err);
          return 0;
        }),
 
        
      ]);


    //   contract.methods.getDownlineRef().call().then(function(result) {
    //     var downlineAddress = result[0];
    //     var downlineId = result[1];
    //     console.log("Downline address: " + downlineAddress);
    //     console.log("Downline ID: " + downlineId);
    // });
               
                      


      setWalletBalance({
      
        reflevelinfo: info1,
       
        
      });
    } 

    catch (err) {
      console.error(err);
      setWalletBalance({
       
        reflevelinfo: 0,
       
       
      });
    }
  };

//   useEffect(() => {
//     fetchContractBNBBalance();
//   }, [web3, chainId]);

  useEffect(() => {
    fetchWalletBalance();
  }, [address, web3, chainId]);




  const reflevelinfos = [
    {
      
      label: "1.",
      rebate: "10%",
      referral: "5%",
      value: walletBalance.reflevelinfo[0],
    
     
    },
    {
      label: "2.",
      rebate: "5%",
      referral: "0%",
      value: walletBalance.reflevelinfo[1],
     
    },
    {
      label: "3.",
      rebate: "4%",
      referral: "0%",
      value: walletBalance.reflevelinfo[2],
     
    },
    {
      label: "4.",
      rebate: "3%",
      referral: "0%",
      value: walletBalance.reflevelinfo[3],
     
    },
    {
      label: "5.",
      rebate: "2%",
      referral: "0%",
      value: walletBalance.reflevelinfo[4],
     
    },
    {
      label: "6",
      rebate: "1.5%",
      referral: "0%",
      value: walletBalance.reflevelinfo[5],
      
    },
    {
      label: "7",
      rebate: "1.5%",
      referral: "0%",
      value: walletBalance.reflevelinfo[6],
     
    },
    {
      label: "8",
      rebate: "1%",
      referral: "0%",
      value: walletBalance.reflevelinfo[7],
      
    },
    {
      label: "9",
      rebate: "1%",
      referral: "0%",
      value: walletBalance.reflevelinfo[8],
     
    },
    {
      label: "10",
      rebate: "1%",
      referral: "0%",
      value: walletBalance.reflevelinfo[9],
     
    },
  
  ];


  return (
    <Card className={classes.card} >
      <CardContent style={{ paddingLeft: 8, paddingRight: 8 }} >
        <Typography variant="h5"  paddingBottom={5} paddingTop={2} style={{color: "#afd5f0" }}>
        Active Referral :
        </Typography>

        <Box style={{ paddingBottom: 5,  paddingTop: 12 }}>
        <Grid container justifyContent="space-between">

        <Typography style={{color: "#afd5f0", fontSize: "15px"}} borderBottom="2px solid"  gutterBottom> Level </Typography>

        <Divider  orientation="vertical" flexItem/>

        <Typography style={{color: "#afd5f0", fontSize: "15px"}} borderBottom="2px solid"  gutterBottom> Rebates </Typography>

        <Divider  orientation="vertical" flexItem />
        
        <Typography style={{color: "#afd5f0", fontSize: "15px"}} borderBottom="2px solid"  gutterBottom> RefBonus </Typography>

        <Divider orientation="vertical" flexItem />

        <Typography style={{color: "#afd5f0", fontSize: "15px"}} borderBottom="2px solid"  gutterBottom> Count </Typography>

        </Grid>
        </Box> 

         <Box  paddingTop={1} paddingBottom={10}>
        {reflevelinfos.map((f) => (
         
            <Grid container key= {f.label} justifyContent="space-between">

              <Typography style={{color: "#afd5f0"}}   textAlign='center'  variant="body1" gutterBottom>
              {f.label}
              </Typography>

             
              <Typography style={{color: "#afd5f0"}}  textAlign='center'  gutterBottom> {f.rebate} </Typography>

          
              <Typography style={{color: "#afd5f0"}}  textAlign='center'  gutterBottom> {f.referral} </Typography>

           
              <Typography style={{color: "#afd5f0"}}   textAlign='center'  gutterBottom> {f.value} </Typography>


            </Grid>


        ))}
        
        </Box>
      </CardContent>
    </Card>
  );
}
