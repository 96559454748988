
export const config = {
  chainId: 56,
  contractAddress: "0xbAee94Ba41737fc86FCd86582c0e37B46AEE62f7",
  scanLink:
    "https://bscscan.com/address/0xbAee94Ba41737fc86FCd86582c0e37B46AEE62f7",
};

// //for test net
// export const config = {
//   chainId: 97,
//   contractAddress: "0x9100f631c0F3f7b8849B49458c3126caae2f7C33",
//   scanLink:
//     "https://testnet.bscscan.com/address/0x9100f631c0F3f7b8849B49458c3126caae2f7C33",
// };