import React, { Fragment } from "react";

const ButtonClock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {
  return (
    <Fragment>
     
       
          <div className="buttonclock">
        
            <section>
              <p>{timerDays}</p>
              {/* <small>Days</small> */}
            </section>
            <span>:</span>
            <section>
              <p>{timerHours}</p>
              {/* <small>Hours</small> */}
            </section>{" "}
            <span>:</span>
            <section>
              <p>{timerMinutes}</p>
              {/* <small>Minutes</small> */}
            </section>{" "}
            <span>:</span>
            <section>
              <p>{timerSeconds}</p>
              {/* <small>Seconds</small> */}
            </section>
          </div>
       
    </Fragment>
  );
};

ButtonClock.defaultProps = {
  timerDays: 0,
  timerHours: 0,
  timerMinutes: 0,
  timerSeconds: 0,
};

export default ButtonClock;