import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cardimage1Url from "../../assets/frame2.webp";

import { styled } from "@mui/system";

// const CardWrapper = styled(Card)({
//   background: "#022e6b",
//   marginBottom: 24,
// });


const useStyles = makeStyles({
  card: {
    // backgroundImage: `url(${process.env.PUBLIC_URL}/src/assets/frame2.webp)`,
    backgroundSize: 'cover',
    marginBottom: 26,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 100,
    backgroundImage: `url(${cardimage1Url})`,
    backgroundPositionX: "center",
    backgroundPositionY: "crnter",
    // boxShadow: "none",
  },

});

const nutritionFacts = [
  {
    label: "Daily Yield Up to ",
    value: "1-1.5",
  },
  // {
  //   label: "Indirect Reward",
  //   value: 6.75,
  // },
  {
    label: "Direct Reward",
    value: 5,
  },
  {
    label: "Rebates Bonus",
    value: 30,
  },
];

export default function NutritionFacts() {

  const classes = useStyles();

  return (
    <Card  className={classes.card}  style={{ paddingLeft: 5, paddingRight: 5 }}>
      <CardContent >
        <Typography paddingTop={8}  style={{ paddingLeft: 8, paddingRight: 8, color: "#afd5f0", display:"flex", justifyContent:"center", alignItems:"center" }} variant="h5" borderBottom="2px solid" paddingBottom={2}>
        Minimum Deposit: 0.2 BNB
        </Typography>
        <Box  paddingTop={2}  paddingBottom={6}>
          {nutritionFacts.map((f) => (
            <Grid container key={f.label} justifyContent="space-around">
              <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>
                {f.label}
              </Typography>
              <Typography style={{color: "#afd5f0"}} gutterBottom>{f.value}%</Typography>
            </Grid>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
