import React, { useRef, useState } from 'react';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { styled } from "@mui/system";
import { FaCopy} from 'react-icons/fa';
import cardimage1Url from "../../assets/frame2.webp";



// const CardWrapper = styled(Card)({
//   background: "#022e6b",
//   marginBottom: 24,
// });


const useStyles = makeStyles({
  card: {
    // check index.css for  backgroundImage: `url(${process.env.PUBLIC_URL}/src/assets/frame2.webp)`,
    backgroundSize: 'cover',
    marginBottom: 26,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 100,
    backgroundImage: `url(${cardimage1Url})`,
    backgroundPositionX: "center",
    backgroundPositionY: "crnter",
    // boxShadow: "none",
  }

});


const Input = styled("input")(({ theme }) => ({
  fontSize: 10,
  fontWeight: 300,
  padding: "10px 12px",
  borderRadius: 0,
  border: "1px solid #555",
  background: "white",
  width: "100%",
  outline: "none",
  color: theme.palette.primary.main,
}));

export default function ReferralLink({ address }) {

  const classes = useStyles();


  const copyicon = <FaCopy />;
  const link = `${window.origin}?ref=${address}`;

  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!');
  };



  return (
    <Card className={classes.card}>
      <CardContent style={{ paddingLeft: 8, paddingRight: 8 }}>

      <Box  paddingTop={0} paddingBottom={10}>

      <Typography style={{color: "#afd5f0", fontSize: "20px"}} variant="h5" paddingTop={0} paddingBottom={8}>
          Referral Link :
        </Typography>
        <Input  ref={textAreaRef} style={{color: "black"}} value={address ? link : ""} readOnly />

       <Typography
         
          textAlign="center"
          variant="body2"
          marginTop={2}
          paddingX={3}
    
        >
        
        {copySuccess}

        <Button 
         variant="contained"
         fullWidth
         marginTop={20}
         color="secondary"
         style={{ background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))"}}
    

         onClick={copyToClipboard} > {copyicon} &nbsp; Copy
         </Button> 
       </Typography>


        <Typography
          textAlign="center"
          variant="body2"
          marginTop={5}
          paddingX={1}
          style={{color: "#afd5f0"}}
        >
          Earn upto 5% Referral bonus + 30% Rebate from your referrals by
          sharing the link.
        </Typography>
         
         </Box>

      </CardContent>
    </Card>
  );
}
