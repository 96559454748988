import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/system";

const CardWrapper = styled(Card)({
  background: "#022e6b",
  marginBottom: 24,
});


export default function NutritionFacts() {
  return (
    <CardWrapper >
      <CardContent >
        <Typography style={{color: "#afd5f0"}} variant="h5" borderBottom="6px solid" paddingBottom={1}>
        Disclaimer
        </Typography>
        <Box  paddingTop={2}>
         
            <Grid container  justifyContent="space-between">
              <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

              WARNING: ApexPro is a high-risk ROI dapp. As such, only invest what you are willing to lose, as you may lose it all.
               
              </Typography>

              
            </Grid>
         
        </Box>
      </CardContent>
    </CardWrapper>
  );
}
