
import * as React from 'react';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Web3 from "web3";

import { useContractContext } from "../../providers/ContractProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import { useEffect, useState } from "react";
import { config } from "../../config";
import Address from './address'
import {FaCalendarWeek} from 'react-icons/fa';

import { styled } from "@mui/system";
import cardimage1Url from "../../assets/frame2.webp";



// const CardWrapper = styled(Card)({
//   background: "#022e6b",
//   marginBottom: 24,
// });

const useStyles = makeStyles({
  card: {
    // check index.css for  backgroundImage: `url(${process.env.PUBLIC_URL}/src/assets/frame2.webp)`,
    backgroundSize: 'cover',
    marginBottom: 26,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 100,
    backgroundImage: `url(${cardimage1Url})`,
    backgroundPositionX: "center",
    backgroundPositionY: "crnter",
    // boxShadow: "none",
  }

});



export default function TopReflevelsInfo() {

  const classes = useStyles();


const { contract, wrongNetwork, getBnbBalance, fromWei, toWei, web3 } = useContractContext();
const { address, chainId } = useAuthContext();
const [contractBNB, setContractBNB] = useState(0);
const clockicon = <FaCalendarWeek />;


const [currentWeek, setCurrentWeek] = useState({


    week: 0,

  
});

const fetchCurrentWeek = async () => {
    if (!web3 || wrongNetwork) {
        setCurrentWeek({
      
        week: 0,
          
          });
          return;
    }

    try {
        const [ weekinfo ]
      = await Promise.all([

        contract.methods
        .getCurrentWeek()
        .call()

        .catch((err) => {
          console.error("currentweek", err);
          return 0;
        }),
 
        
      ]);
               

      setCurrentWeek({
      
        week: weekinfo,
      
        
      });
    } 

    catch (err) {
      console.error(err);
      setCurrentWeek({
       
        week: 0,
       
      });
    }

  };



const [walletBalance, setWalletBalance] = useState({


    contestants: 0,

  
});

const fetchWalletBalance = async () => {
    if (!web3 || wrongNetwork || !address) {
      setWalletBalance({
      
        contestants: 0,
      
       
      });
      return;
    }

    try {
        const [ info1 ]
      = await Promise.all([

       
        contract.methods
        .getLeaderboard(currentWeek.week)
        .call()

        // $("#lvl1").html(info[0]);

        .catch((err) => {
          console.error("topreferrer", err);
          return 0;
        }),
 
        
      ]);
               
                      


      setWalletBalance({
      
        contestants: info1,
      
        
      });
    } 

    catch (err) {
      console.error(err);
      setWalletBalance({
       
        contestants: 0,
       
       
      });
    }
  };

//   useEffect(() => {
//     fetchContractBNBBalance();
//   }, [web3, chainId]);


useEffect(() => {
    fetchCurrentWeek();
}, [web3, chainId]);

  useEffect(() => {
    fetchWalletBalance();
  }, [address, web3, chainId]);



// top referrer
  const refcontestant = walletBalance.contestants;

// top referrer addrs
  const toprefaddress0 =  refcontestant ? refcontestant[0].addr.toString() : '';
  const toprefaddress1 =  refcontestant ? refcontestant[1].addr.toString() : '';
  const toprefaddress2 =  refcontestant ? refcontestant[2].addr.toString() : '';
  const toprefaddress3 =  refcontestant ? refcontestant[3].addr.toString() : '';
  const toprefaddress4 =  refcontestant ? refcontestant[4].addr.toString() : '';



  // top referrer amount
  const toprefamount0 = refcontestant[0] ? refcontestant[0].amount : '';
  const toprefamount1 = refcontestant[1] ? refcontestant[1].amount : '';
  const toprefamount2 = refcontestant[2] ? refcontestant[2].amount : '';
  const toprefamount3 = refcontestant[3] ? refcontestant[3].amount : '';
  const toprefamount4 = refcontestant[4] ? refcontestant[4].amount : '';




  // function renderLeaderboard(contestants){
  //   let i = 0;
  
  //   contestants.forEach(contestant => {
      
  
  //     i++;
  //     if (contestant.addr != "0x0000000000000000000000000000000000000000"){
  //       //console.log("no key");
  //       referee = "<div class=\"row\"><div class=\"col-3\">" + ((parseFloat(contestant.amount))/10**18).toFixed(6) + "</div><div style=\"word-break: break-all\" class=\"col-9 users truncate\">" + contestant.addr + "</div></div>";
  //       $("#referee-" + i).html(referee);
  //     }
  //     //console.log(key + " " + value + " " + i);
  
  //   });
  // }
 





  // const topreflevelinfos = [
  //   {
  //     topaddress: refcontestant ? refcontestant[0].addr.toString().slice(0, -5) : '',
  //     topamount:  fromWei(`${toprefamount0}`),
    
  //   },
  //   {
  //     topaddress: refcontestant ? refcontestant[1].addr.toString().slice(0, -5) : '',
  //     topamount:  fromWei(`${toprefamount1}`),
      
  //     },
  //     {
  //     topaddress: refcontestant ? refcontestant[2].addr.toString().slice(0, -5) : '',
  //     topamount:  fromWei(`${toprefamount2}`),
      
  //     },
  //     {
  //     topaddress: refcontestant ? refcontestant[3].addr.toString().slice(0, -5) : '',
  //     topamount:  fromWei(`${toprefamount3}`),
        
  //     },
  //     {
  //     topaddress: refcontestant ? refcontestant[4].addr.toString().slice(0, -5) : '',
  //     topamount:  fromWei(`${toprefamount4}`),
          
  //     }, 
   
  
  // ];


  return (
   
    <Card className={classes.card} >
   
    <CardContent style={{color: "#afd5f0"}}>

    <Typography style={{color: "#afd5f0", paddingTop: 3}} variant="h5" paddingBottom={5}>
     {clockicon} Top 5 Week ({currentWeek.week}) referrer.
        </Typography>

      <Box style={{ paddingTop: 0, paddingBottom: 60 }}> 

      <Grid
        container
        flexWrap= "nowrap"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Typography variant="body1"> 1.
        <Address style={{ fontFamily: 'monospace', color: 'green' }} a={toprefaddress0} />
        </Typography>
        
        <Typography variant="h6" style={{color: 'white', fontSize: "18px"}} > {fromWei(`${toprefamount0}`)} BNB</Typography>
      </Grid>

      <Grid
        container
        flexWrap= "nowrap"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Typography variant="body1"> 2.
        <Address style={{ fontFamily: 'monospace', color: 'green' }} a={toprefaddress1} />
        </Typography>
        
        <Typography variant="h6" style={{ color: 'white', fontSize: "18px"}}>{fromWei(`${toprefamount1}`)} BNB</Typography>
      </Grid>

      <Grid
        flexWrap= "nowrap"
        container
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Typography variant="body1"> 3.
        <Address style={{ fontFamily: 'monospace', color: 'green' }} a={toprefaddress2} />
        </Typography>
        
        <Typography variant="h6" style={{color: 'white', fontSize: "18px"}}>{fromWei(`${toprefamount2}`)} BNB</Typography>
      </Grid>

      <Grid
       flexWrap= "nowrap"
        container
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Typography variant="body1"> 4.
        <Address style={{ fontFamily: 'monospace', color: 'green' }} a={toprefaddress3} />
        </Typography>
        
        <Typography variant="h6" style={{color: 'white', fontSize: "18px"}}>{fromWei(`${toprefamount3}`)} BNB</Typography>
      </Grid>

      <Grid
        flexWrap= "nowrap"
        container
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Typography variant="body1"> 5.
        <Address style={{ fontFamily: 'monospace', color: 'green' }} a={toprefaddress4} />
        </Typography>
        
        <Typography variant="h6" style={{ color: 'white', fontSize: "18px"}}>{fromWei(`${toprefamount4}`)} BNB</Typography>
      </Grid>

      </Box>
     

    </CardContent>

  </Card>

  

  );
}
