
import { styled } from "@mui/system";

import Connect from "./components/Connect";
import Header from "./components/Header";
import BakeCard from "./components/BakeCard";
import NutritionFacts from "./components/NutritionFacts";
import RefLevel from "./components/RefLevel";
// import CheckReflevel from "./components/CheckReflevel";
import TopReferrer from "./components/TopReferrer";
import ReturnCalculator from "./components/ReturnCalculator";
import ReferralLink from "./components/ReferralLink";
import { useAuthContext } from "../providers/AuthProvider";
import Footer from "./components/Footer";
import FAQs from "./components/FAQ";
import Disclaimer from "./components/Disclaimer";
import OtherInfo from "./components/OtherInfo";
import DepositInfo from "./components/DepositInfo";
import DownlineInfo from "./components/DownlineInfo";
import { makeStyles } from '@material-ui/core/styles';

import TwitterFeed from "./components/TwitterFeed";

const Wrapper = styled("div")(({ theme }) => ({
  maxWidth:  400,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export default function Home() {
  const { address } = useAuthContext();

  return (
    <Wrapper>
      <Connect />
      <Header />
     
      <BakeCard />
      <NutritionFacts />
      <DepositInfo />
      <RefLevel />
      <DownlineInfo />
      <TopReferrer />
      <ReferralLink address={address} />
      {/* <CheckReflevel /> */}
      {/* <ReturnCalculator /> */}
      {/* <TwitterFeed /> */}
      <OtherInfo />
      {/* <Disclaimer /> */}
      <FAQs />
      <Footer />
    </Wrapper>
  );
}
