/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
// import CardContent from "@mui/material/CardContent";
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import logo from "../../assets/FullLogo.png";
import esIcon from "../assets/ESIcon.png";
import auditIcon from "../assets/Audit.png";
// import MenuBar from "./MenuBar";
import Connect from "./Connect";
// import Clock from "./Clock";
import awardbuttonimg from "../../assets/awardbutton.png";



const Wrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingBottom: 25,
  [theme.breakpoints.down("md")]: {
    h5: {
      fontSize: 20,
      margin: 0,
    },
  },
}));

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  // backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  
}));




export default function Header() {



  // timer start
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  const [distance, setDistance] = useState();

  let interval;

  // const startTimer = () => {
  //   const countDownDate = new Date("April 29 2022 4:00:00 UTC 2");

  //   interval = setInterval(() => {
  //     const now = new Date();

  //     const distance = Date.parse(countDownDate) - Date.parse(now);

  //     const days = Math.floor(distance / (24 * 60 * 60 * 1000));
  //     const hours = Math.floor(
  //       (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
  //     );
  //     const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
  //     const seconds = Math.floor((distance % (60 * 1000)) / 1000);

  //     if (distance < 0) {
  //       // Stop Timer

  //       clearInterval(interval.current);
  //     } else {
  //       // Update Timer
  //       setTimerDays(days);
  //       setTimerHours(hours);
  //       setTimerMinutes(minutes);
  //       setTimerSeconds(seconds);
  //       setDistance(distance);
  //     }
  //   });
  // };

  useEffect(() => {
    // startTimer();
  });

// timer end


  return (
    <Wrapper>
     {/* <MenuBar/>  */}
      <img src={logo} alt="" width={"100%"} style={{ display: "flex", marginTop: 10 }} />
      <Connect responsive={false} />

      <Div>

      <Typography style={{color: "#afd5f0", fontSize: "16px"}} variant="h5" marginTop={3}>

      LEVERAGE YOUR BNB AND GET ALLOCATION UP TO 1%-1.5% DAILY BY PARTICIPATING IN OUR YIELD FARMING PLATFORM. MAXIMIZED YOUR ALLOCATION BY BUILDING A COMMUNITY OF CONTRIBUTORS AND GET EVEN MORE THROUGH OUR AFFILIATE SYSTEM ALLOCATION PLAN. "HELP TO GET HELP" WHILE BUILDING A SUSTAINABLE COMMUNITY.

    {/* <h2>{timerComponents.length ? timerComponents : <span>Time's up!</span>}remaining!</h2> */}
      </Typography>

      </Div>

      <Stack direction="row" spacing={2} style={{ justifyContent : "center" }} >
      {/* <Button variant="outlined" href="https://bscscan.com/address/0xe0c12b51eb0859d28df91bc547219f65c75d0e40" target="__blank" >
      <img src={esIcon} alt="" width={40} height={40} /> CONTRACT </Button> */}
     
      <Button variant="contained"
        color="secondary"
        style={{ backgroundImage: `url(${awardbuttonimg})`,
        backgroundColor: "transparent",
        // boxShadow: "none",
        backgroundSize: 'cover',
        marginLeft: 5,
        marginRight: 5,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 100,
        backgroundPositionX: "center"}} 

        href="https://www.encryptosecurity.com/AuditRecord?project=86#APEXPro" target="__blank">

       <img src={auditIcon} alt="" width={40} height={40} />
        AUDIT REPORT
      </Button>
      </Stack>


      {/* <h4>Launched at Friday Apr 29 2022 UTC 4</h4> */}
       {/* <h4>Make the world more safe and green</h4> */}

      {/* <Typography style={{color: "Black"}} variant="h6" marginTop={-3}>
    
      {distance ? <Clock
        timerDays={timerDays}
        timerHours={timerHours}
        timerMinutes={timerMinutes}
        timerSeconds={timerSeconds}
      />
      :
      <span>Let's plant Royal Trees now!</span>}
      </Typography> */}

    </Wrapper>
  );
}
