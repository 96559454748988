import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import NumberInput from "../../components/NumberInput";
import { useEffect, useState } from "react";
import getFormattedNumber from './get-formatted-number'


const CardWrapper = styled(Card)({
    background:  "#022e6b",
    marginBottom: 24,
});



export default function ReturnCalculator() {


    // return calculator state
    const [apr, setAPR] = useState();
    const [approxInterval, setApproxInterval] = useState();
    const [approxDays, setApproxDays] = useState();
    const [approxDeposit, setApproxDeposit] = useState();
    // return calculator state


     const onUpdateAPR = (value) => {
        setAPR(value);
      };

      const onUpdateApproxDeposit = (value) => {
        setApproxDeposit(value);
      };

      const onUpdateApproxDays = (value) => {
        setApproxDays(value);
      };

      const onUpdateApproxInterval = (value) => {
        setApproxInterval(value);
      };
    

    // return calaculator function

    // const getApproxReturn = () => {
       
    //     return (approxDeposit * apr / 100 * approxDays * approxInterval)
    // }


    const getApproxReturn = (approxDeposit, approxDays, apr, approxInterval) => {
        const r = apr / 100;
        const amount = approxDeposit * (Math.pow((1 + (r / approxInterval)), (approxInterval * approxDays)));
        const interest = amount - approxDeposit;
        return interest;
     };

    // return calaculator function end


    return (

       
      <CardWrapper>
    
      <CardContent style={{color: "#F5BD01"}}>

      <Typography style={{color: "#F5BD01"}} gutterBottom variant="h5" textAlign="center">
        Reward Calculator
      </Typography>

      <Typography variant="body1" fontWeight="bolder">
          Input APR
      </Typography>

      <Box paddingTop={4} paddingBottom={3}>

        <Box>
          <NumberInput
            value={apr || ''}
            onChange={(value) => onUpdateAPR(value)}
            text = {'0 apr'}            />
         
        </Box>
  
      </Box>


      <Typography variant="body1" fontWeight="bolder">
          Re-Grow count per day
      </Typography>

      <Box paddingTop={4} paddingBottom={3}>

        <Box>
          <NumberInput
            value={approxInterval || ''}
            onChange={(value) => onUpdateApproxInterval(value)}
            text = {'0 times'} 
          />
          
        </Box>
  
      </Box>



      <Typography variant="body1" fontWeight="bolder">
       Compounding Duration (Days)
      </Typography>

      <Box paddingTop={4} paddingBottom={3}>

        <Box>
          <NumberInput
            value={approxDays || ''}
            onChange={(value) => onUpdateApproxDays(value)}
            text = {'0 days'} 
          />
        </Box>
  
      </Box>


      <Typography variant="body1" fontWeight="bolder">
         Input BNB after fee 
      </Typography>

      <Box paddingTop={4} paddingBottom={3}>

        <Box>
          <NumberInput
            value={approxDeposit || ''}
            onChange={(value) => onUpdateApproxDeposit(value)}
            text = {'0 bnb'} 
          />
        </Box>

        <Typography variant="body1" fontWeight="bolder">
         Return = {getFormattedNumber(getApproxReturn(approxDeposit, approxDays, apr, approxInterval), 6)} BNB
       </Typography>
      
  
      </Box>

        </CardContent>

</CardWrapper>



    );
}
