import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { makeStyles } from '@material-ui/core/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


import Web3 from "web3";

import { useContractContext } from "../../providers/ContractProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import { useEffect, useState } from "react";
import { config } from "../../config";
import Address from './address'

// import { styled } from "@mui/system";
import cardimage1Url from "../../assets/frame2.webp";

// const CardWrapper = styled(Card)({
//   background: "#022e6b",
//   marginBottom: 24,
// });

const useStyles = makeStyles({
  card: {
    // check index.css for  backgroundImage: `url(${process.env.PUBLIC_URL}/src/assets/frame2.webp)`,
    backgroundSize: 'cover',
    marginBottom: 26,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 100,
    backgroundImage: `url(${cardimage1Url})`,
    backgroundPositionX: "center",
    backgroundPositionY: "crnter",
    // boxShadow: "none",
  }

});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '4px solid #010D34',
  borderRadius: '50px 21px',
  boxShadow: 24,
  p: 4,
};



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? "#010C2F" : "#fffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.secondary.main,
  boxShadow: "0px 0px 5px 1px  #010C2F",
  borderRadius: 5,
  //  backgroundColor: "transparent",
}));




const  DepositsInfo = () => {

const classes = useStyles();
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);


const { contract, wrongNetwork, getBnbBalance, fromWei, toWei, web3 } = useContractContext();
const { address, chainId } = useAuthContext();
const [contractBNB, setContractBNB] = useState(0);
const [userDepositInfo, setUserDepositInfo] = useState([]);
// const [depositindex, setDepositindex] = useState({
  
//   index: 0,

// });



  const fetchUserDepositInfo = async () => {
    if (!web3 || wrongNetwork || !address) {

      setUserDepositInfo([]);

      // setDepositindex({
      
      //   index: 0,
       
      // });


      return;
    }
  
    try {


      const [ index ]
      = await Promise.all([

       
        contract.methods
        .getUserAmountOfDeposits(address)
        .call()


        .catch((err) => {
          console.error("deposit index", err);
          return 0;
        }),
 
        
      ]);


      // setDepositindex({
      
      //   index: index,
       
      // });


      // depositinfo call start from here

      let results = [];
      // const index = 1;
      for (let i = 0; i < index; i++) {
        results.push(
          contract.methods
            .getUserDepositInfo(address, i)
            .call()
            .catch((err) => {
              console.error("deposit info", err);
              return {plan: 0, percent: 0, amount: 0, start: 0, finish: 0 };
            })
        );
      }

     const alldeposits = await Promise.all(results);

     const mappedDepositInfos = alldeposits.map((depositInfo) => {
        return {
          // index: depositInfo.index,
          plan: depositInfo.plan,
          percent: depositInfo.percent,
          amount: fromWei(`${depositInfo.amount}`),
          start:  (`${depositInfo.start ? new Date(depositInfo.start*1000).toLocaleString() : ""}`),
          finish: (`${depositInfo.finish ? new Date(depositInfo.finish*1000).toLocaleString() : ""}`),
        };
      });
  
      setUserDepositInfo(mappedDepositInfos);
      
    } catch (err) {
      console.error(err);

      setUserDepositInfo([]);

      //  setDepositindex({
         
      //   index: 0,
       
       
      // });
    }
  };
  

  useEffect(() => {
    fetchUserDepositInfo();
  }, [address, web3, chainId]);

  


  return (
    <Card className={classes.card} style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center", paddingLeft: 5, paddingRight: 5 }} >
    
      <CardContent style={{  display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center", paddingLeft: 2, paddingRight: 2 }} >
        <Typography paddingTop={8}  style={{ paddingLeft: 8, paddingRight: 8, color: "#afd5f0", display:"flex", justifyContent:"center", alignItems:"center" }} variant="h5" borderBottom="2px solid" paddingBottom={2}>
          you have  {userDepositInfo.length} Deposit:
        </Typography>


        {/* modal button start */}

        <Box paddingTop={1} paddingBottom={10}>

        <Box paddingTop={0} paddingBottom={1}>

        {/* <div> */}

      <Button   variant="contained"
                  color="secondary"
                  style={{ fontSize: "18px", display: "inline-flex", justifyContent:"center", alignItems:"space-around", marginRight: 10, marginLeft: 10, background: "linear-gradient(rgb(0 0 0 / 15%), rgb(84, 2, 107))"}}
                   onClick={handleOpen}> Check Details</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>

        { userDepositInfo.length ? (

          <Box  style={{background: "none" }} sx={{  flexGrow: 1 }}>

          { userDepositInfo.map((deposit, index)=> (

            <Item  key = {index} style={{ marginBottom: 8, marginTop: 8, marginRight: 8, marginLeft: 8, background: "linear-gradient(#f5f5f5, #f5f5f5)", display: "flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}> 
             <Typography variant="body2" style={{ color: "#3F075D", fontSize: "15px"}} > No: {index}  </Typography>
             <Divider orientation="vertical" flexItem >  </Divider>
             <Typography variant="body2" style={{ color: "#3F075D", fontSize: "15px"}} > Plan: {deposit.plan != 0 ? "Long" : "Short" } </Typography>
             <Divider orientation="vertical" flexItem >  </Divider>
             <Typography variant="body2" style={{ color: "#3F075D", fontSize: "15px"}} > Amount: {deposit.amount} BNB </Typography>
             <Divider orientation="vertical" flexItem >  </Divider>
             <Typography variant="body2" style={{ color: "#3F075D", fontSize: "15px"}}> Start: {deposit.start} </Typography>
             </Item>
  
         ))}

          </Box>

          ) : (
           
            <Typography variant="body2" style={{display: "flex", flexDirection:"row", justifyContent:"center", alignItems:"center", color: "#3F075D", fontSize: "15px"}}>  No deposit information found. </Typography>
          
         )}


        </Fade>
      </Modal>

    {/* </div> */}

    </Box>

    </Box>
       
      </CardContent>
    </Card>
  );
}


export default DepositsInfo;