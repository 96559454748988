/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import CardContent from "@mui/material/CardContent";
// import Card from "@mui/material/Card";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from '@mui/material/CircularProgress';
// import Button from '@mui/joy/Button';
// import CircularProgress from '@mui/joy/CircularProgress';
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
// import Popper from '@mui/material/Popper';
// import MenuItem from '@mui/material/MenuItem';
// import MenuList from '@mui/material/MenuList';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import FormHelperText from '@mui/material/FormHelperText';
import { useLocation } from "react-router-dom";
import Web3 from "web3";

import PriceInput from "../../components/PriceInput";
import { useContractContext } from "../../providers/ContractProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import { useEffect, useState } from "react";
import { config } from "../../config";
// import Clock from "./Clock";
import ButtonClock from "./ButtonClock";
import Address from './address'
import { FaMoneyCheckAlt, FaPiggyBank, FaWallet, FaSlideshare, FaShareAlt, FaFileInvoiceDollar, FaPeopleArrows} from 'react-icons/fa';
import { BsSave2Fill, BsFillArrowUpSquareFill, BsPeopleFill } from 'react-icons/bs';
import useAxios from "../../components/UseAxios";
// import { currencyFormat } from  "../../components/CurrencyFormat";
import cardimage2Url from "../../assets/frame4.webp";
import cardimageUrl from "../../assets/frame3.webp";
import cardimage1Url from "../../assets/frame2.webp";



// const CardWrapper = styled(Card)({
//   // background: "none",
//   marginBottom: 24,
// });


const useStyles = makeStyles({

  card: {
    backgroundSize: 'cover',
    marginBottom: 26,
    backgroundRepeat: "no-repeat",
    // backgroundImage: `url(${cardimage1Url})`,
    // backgroundPositionX: "center",
    // backgroundPositionY: "crnter",
    // border: '1px solid #E840F9',
  },

  Paper: {

    // border: '1px solid #E840F9',
    // backgroundImage: `url(${cardimageUrl})`,
    // backgroundColor: "transparent",
    // backgroundSize: 'cover',
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: 100,
    // backgroundPositionX: "center",
    // backgroundPositionY: "crnter",
  }
});


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  // height: 130,
  // background: 'none',
  // border: '1px solid #41dff5',
  transition: 'all 275ms ease',
  height: 200,
  lineHeight: 'auto',
  backgroundImage: `url(${cardimage2Url})`,
  backgroundColor: "transparent",
  backgroundSize: 'cover',
  backgroundRepeat: "no-repeat",
  backgroundPosition: 100,
  backgroundPositionX: "center",
  backgroundPositionY: "crnter",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url(${cardimageUrl})`,
  },

  
}));

// const Div = styled('div')(({ theme }) => ({
//   ...theme.typography.button,
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(1),
  
// }));

const ButtonContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    "> div": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

// let timeout = null;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function BakeCard() {

  const classes = useStyles();

  const depositicon = <FaPiggyBank style={{ marginRight: "5px"}} />; 
  const withdrawicon = <FaMoneyCheckAlt style={{marginRight: "5px",  position: 'center'}} />;
  const walleticon = <FaWallet style={{ marginRight: "5px"}} />;
  const tdepositicon = <BsSave2Fill style={{ marginRight: "5px"}} />; 
  const rbonusicon = <FaSlideshare style={{ marginRight: "5px"}} />; 
  const widthrawicon = <BsFillArrowUpSquareFill style={{ marginRight: "5px"}} />; 
  const rebateicon = <FaShareAlt style={{ marginRight: "5px"}} />;
  const yieldicon = <FaFileInvoiceDollar style={{ marginRight: "5px"}} />;
  const refnumbericon = <BsPeopleFill style={{ marginRight: "5px"}} />; 
  const refbyicon = <FaPeopleArrows style={{ marginRight: "5px"}} />;


  const { contract, wrongNetwork, getBnbBalance, fromWei, toWei, web3 } = useContractContext();
  const { address, chainId } = useAuthContext();

  const blockaccount = [ 
    {addrss: "0x62246b9e51f07a5f14c7eb14d97d4391d8d71df5"},
    {addrss: "0xbdBCDEA5BA8dC89a666cE87c7cBDb84aEaa4ee5A"},
    // {addrss: "0x8169d655a9A2642Af968dbCaEAE6Ed33e5e1A86a"},
    // {addrss: "0x59DDbe2088b87ed7eCf553CD17C82d5ef60Da147"},
    // {addrss: "0xBF3b8bFAe2B0fBe9d744f02B5d5D9c8b495434eB"},
    // {addrss: "0x0896151CDCbC1cB5a494995F9dFB86f07810e1b0"},
    // {addrss: "0x6418Fa991302b5fEb92709104d9FC8EB005DB96F"},
    // {addrss: "0xA2808D78b4860cC03a10e69683815C1a4CF8C047"},
    // {addrss: "0xF1166404B3A11295840BC74e1423A825b85f2002"},
    // {addrss: "0xAa84d4079d4BB9Df2ecF16241BF902F03bf384fd"},
    // {addrss: "0x21Bee2190A63e4cC56C64Cb67967E3849D84F1E5"},
    // {addrss: "0xe29286849921DC4e94DD6134771A60d09548ac8B"},
    // {addrss: "0x744fB8745A0985BbE50Df173a1d970b8dA2E0037"},
    // {addrss: "0x21Ceb09e3473CC6BecAFe3D82F567cF4DC91d1cB"},
    // {addrss: "0x9Da4E08Ebf37034A4c523D8Df7b97442Fcf67f13"},
    // {addrss: "0x5C23c81780dC5b5b662Fc6931195383DF7CE690C"},
    // {addrss: "0xe601256a652424F26da778515AfF98951903313a"},
    // {addrss: "0xA55CbcFe0730D6F625fCb714cD052D5D06d9Ad31"},
    // {addrss: "0xDc0Ce5b6ce0e90ED3f5bD353a6Af9058acffe0De"},
    // {addrss: "0x74784aE958a74c0da8AbDcDaA8a63EE6Ef0d5ce5"},
    // {addrss: "0x037b00E4D6CB218f207e0A2b6c722e496eEB4d38"},
    // {addrss: "0xfcEE8f689bEd23bCFcaDF6b3c9ee22CC0E05F8b0"},
    // {addrss: "0x1e2C9a0Ef54EF6A5884e0801BbF52b3031a49045"}
    // {addrss: "0xb05c3504b78E899784dD3c4a30649515Ed678AaE"}

      ];

  const blockedaddrs = blockaccount.find(obj => {
    return obj.addrss === address; 
    });
      
  // const blockaccount = address ?  blokedaddress : "" ; 
  const [contractBNB, setContractBNB] = useState(0);
  const [walletBalance, setWalletBalance] = useState({
    bnb: 0,
    power: 0,
    beans: 0,
    market: 0,
    refer: 0,
    refcount: 0,
    refreward: 0,
    rewards: 0,
    allseedreward: 0,
    longyield: 0,
    longdeposit: 0,
    withdrawtimer: 0,
    withdrawtimer2: 0,
    withdrawtimer3: 0,
    yieldtimer: 0,
  });
  const [bakeBNB, setBakeBNB] = useState("");
  const [calculatedBeans, setCalculatedBeans] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const query = useQuery();
  const {response} = useAxios('simple/price?ids=binancecoin&vs_currencies=usd');

  const fetchContractBNBBalance = () => {
    if (!web3 || wrongNetwork) {
      setContractBNB(0);
      return;
    }
    getBnbBalance(config.contractAddress).then((amount) => {
      setContractBNB(fromWei(amount));
    });
  };

  const fetchWalletBalance = async () => {
    if (!web3 || wrongNetwork || !address) {
      setWalletBalance({
        bnb: 0,
        power: 0,
        beans: 0,
        market: 0,
        refer: 0,
        refcount: 0,
        refreward: 0,
        rewards: 0,
        allseedreward: 0,
        longyield: 0,
        longdeposit: 0,
        withdrawtimer: 0,
        withdrawtimer2: 0,
        withdrawtimer3: 0,
        yieldtimer: 0,
      });
      return;
    }

    try {
      const [bnbAmount, powerAmount, beansAmount, marketAmount, referredby, refercount, refrewards, rewardsAmount, seedReward, longYield, longDeposit, withdwarTimer, withdwarTimer2, withdwarTimer3, yldtimer ] = await Promise.all([

        getBnbBalance(address),

        contract.methods
        .getUserTotalDeposits(address)
        .call()
        .catch((err) => {
          console.error("mytotalcompounds", err);
          return 0;
        }),

        contract.methods
          .getUsershortDividends(address)
          .call()
          .catch((err) => {
            console.error("short yield", err);
            return 0;
          }),

          contract.methods
          .getUserTotalWithdrawn(address)
          .call()
          .catch((err) => {
            console.error("total withdrawn", err);
            return 0;
          }),

          contract.methods
          .getUserReferrer(address)
          .call()
          .catch((err) => {
            console.error("myreferrer", err);
            return 0;
          }),

          contract.methods
          .getUserTotalReferrals(address)
          .call()
          .catch((err) => {
            console.error("myreferralcount", err);
            return 0;
          }),

          contract.methods
          .getcurrentseedincome(address)
          .call()
          .catch((err) => {
            console.error("pendingseedincome", err);
            return 0;
          }),

        contract.methods
          .getUserReferralBonus(address)
          .call()
          .catch((err) => {
            console.error("referralbonus", err);
            return 0;
          }),

          contract.methods
          .getUserSeedIncome(address)
          .call()
          .catch((err) => {
            console.error("totalseedincomegot", err);
            return 0;
          }),


          contract.methods
          .getUserlongDividends(address)
          .call()
          .catch((err) => {
            console.error("long yield", err);
            return 0;
          }),


          contract.methods
          .getUserLongDeposits(address)
          .call()
          .catch((err) => {
            console.error("long deposits", err);
            return 0;
          }),


          contract.methods
          .claimTimer(address)
          .call()
          .catch((err) => {
            console.error("withdrawcooldowntimer for short ", err);
            return 0;
          }),

          contract.methods
          .claimTimer2(address)
          .call()
          .catch((err) => {
            console.error("withdrawcooldowntimer2 for reward", err);
            return 0;
          }),

          contract.methods
          .claimTimer3(address)
          .call()
          .catch((err) => {
            console.error("withdrawcooldowntimer3 for long", err);
            return 0;
          }),

          contract.methods
          .yieldTimer(address)
          .call()
          .catch((err) => {
            console.error("yieldtimer", err);
            return 0;
          }),
      ]);
      setWalletBalance({
        bnb: fromWei(`${bnbAmount}`),
        power: fromWei(`${powerAmount}`),
        beans: fromWei(`${beansAmount}`),
        market: fromWei(`${marketAmount}`),
        refer: referredby,
        refcount: refercount,
        refreward: fromWei(`${refrewards}`),
        rewards: fromWei(`${rewardsAmount}`),
        allseedreward:  fromWei(`${seedReward}`),
        longyield:  fromWei(`${longYield}`),
        longdeposit:  fromWei(`${longDeposit}`),
        withdrawtimer: withdwarTimer,
        withdrawtimer2: withdwarTimer2,
        withdrawtimer3: withdwarTimer3,
        yieldtimer: yldtimer,
      });
    } catch (err) {
      console.error(err);
      setWalletBalance({
        bnb: 0,
        power: 0,
        beans: 0,
        market: 0,
        refer: 0,
        refcount: 0,
        refreward: 0,
        rewards: 0,
        allseedreward: 0,
        longyield: 0,
        longdeposit: 0,
        withdrawtimer: 0,
        withdrawtimer2: 0,
        withdrawtimer3: 0,
        yieldtimer: 0,
      });
    }
  };

  useEffect(() => {
    fetchContractBNBBalance();
  }, [web3, chainId]);

  useEffect(() => {
    fetchWalletBalance();
  }, [address, web3, chainId]);

  const onUpdateBakeBNB = (value) => {
    setBakeBNB(value);
  };

  const getRef = () => {
    const ref = Web3.utils.isAddress(query.get("ref"))
      ? query.get("ref")
      : "0x31142224Af9e7eb8545c9cb73B017232DfD3A9d9";
    return ref;
  };

  const investshort = async () => {
    setLoading1(true);

    const ref = getRef();

    try {
      await contract.methods.invest(ref, 0).send({
        from: address,
        value: toWei(`${bakeBNB}`),
      });
    } catch (err) {
      console.error(err);
    }
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading1(false);
  };


  const investlong = async () => {
    setLoading1(true);

    const ref = getRef();

    try {
      await contract.methods.invest(ref, 1).send({
        from: address,
        value: toWei(`${bakeBNB}`),
      });
    } catch (err) {
      console.error(err);
    }
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading1(false);
  };


  const reBake = async () => {
    setLoading(true);

    // const ref = getRef();

    try {
      await contract.methods.reinvest().send({
        from: address,
      });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const eatBeans = async () => {
    setLoading2(true);

    try {
      await contract.methods.withdraw().send({
        from: address,
      });
    } catch (err) {
      console.error(err);
    }
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading2(false);
  };

  const eatAllLong = async () => {
    setLoading4(true);

    try {
      await contract.methods.withdrawAllLong().send({
        from: address,
      });
    } catch (err) {
      console.error(err);
    }
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading4(false);
  };

  const eatAllShort = async () => {
    setLoading3(true);

    try {
      await contract.methods.withdrawAllShort().send({
        from: address,
      });
    } catch (err) {
      console.error(err);
    }
    fetchWalletBalance();
    fetchContractBNBBalance();
    setLoading3(false);
  };

  const yieldclick = async () => {
    setLoading(true);

    try {
      await contract.methods.yieldClick().send({
        from: address,
      });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const refaddr = walletBalance.refer;

  const refadrs = refaddr.toString().slice(2);




// timer withdrawal start
const [wtimerDays, setWTimerDays] = useState();
const [wtimerHours, setWTimerHours] = useState();
const [wtimerMinutes, setWTimerMinutes] = useState();
const [wtimerSeconds, setWTimerSeconds] = useState();
const [cooldowndistance, setCooldownDistance] = useState();



let interval;

const withdrawstartTimer = () => {

  const unixTime = walletBalance.yieldtimer;
 
  const countDownDate = new Date(unixTime*1000);

  interval = setInterval(() => {
    const wnow = new Date();

    const cooldowndistance = Date.parse(countDownDate) - Date.parse(wnow);

    const wdays = Math.floor(cooldowndistance / (24 * 60 * 60 * 1000));
    const whours = Math.floor(
      (cooldowndistance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
    );
    const wminutes = Math.floor((cooldowndistance % (60 * 60 * 1000)) / (1000 * 60));
    const wseconds = Math.floor((cooldowndistance % (60 * 1000)) / 1000);

    if (cooldowndistance < 0) {
      // Stop Timer

      clearInterval(interval.current);
    } else {
      // Update Timer
      setWTimerDays(wdays);
      setWTimerHours(whours);
      setWTimerMinutes(wminutes);
      setWTimerSeconds(wseconds);
      setCooldownDistance(cooldowndistance);
     
    }
  });
};

useEffect(() => {
  withdrawstartTimer();
});

// withdraw timer end



// timer withdrawal2 start
const [wtimerDays2, setWTimerDays2] = useState();
const [wtimerHours2, setWTimerHours2] = useState();
const [wtimerMinutes2, setWTimerMinutes2] = useState();
const [wtimerSeconds2, setWTimerSeconds2] = useState();
const [cooldowndistance2, setCooldownDistance2] = useState();


let interval2;

const withdrawstartTimer2 = () => {

  const unixTime2 = walletBalance.withdrawtimer; // for short withdraw
 
  const countDownDate2 = new Date(unixTime2*1000);

  interval2 = setInterval(() => {
    const wnow2 = new Date();

    const cooldowndistance2 = Date.parse(countDownDate2) - Date.parse(wnow2);

    const wdays2 = Math.floor(cooldowndistance2 / (24 * 60 * 60 * 1000));
    const whours2 = Math.floor(
      (cooldowndistance2 % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
    );
    const wminutes2 = Math.floor((cooldowndistance2 % (60 * 60 * 1000)) / (1000 * 60));
    const wseconds2 = Math.floor((cooldowndistance2 % (60 * 1000)) / 1000);

    if (cooldowndistance2 < 0) {
      // Stop Timer

      clearInterval(interval2.current);
    } else {
      // Update Timer
      setWTimerDays2(wdays2);
      setWTimerHours2(whours2);
      setWTimerMinutes2(wminutes2);
      setWTimerSeconds2(wseconds2);
      setCooldownDistance2(cooldowndistance2);
     
    }
  });
};

useEffect(() => {
  withdrawstartTimer2();
});

// withdraw2 timer end


// timer withdrawal3 start
const [wtimerDays3, setWTimerDays3] = useState();
const [wtimerHours3, setWTimerHours3] = useState();
const [wtimerMinutes3, setWTimerMinutes3] = useState();
const [wtimerSeconds3, setWTimerSeconds3] = useState();
const [cooldowndistance3, setCooldownDistance3] = useState();


let interval3;

const withdrawstartTimer3 = () => {

  const unixTime3 = walletBalance.withdrawtimer3;
 
  const countDownDate3 = new Date(unixTime3*1000);

  interval3 = setInterval(() => {
    const wnow3 = new Date();

    const cooldowndistance3 = Date.parse(countDownDate3) - Date.parse(wnow3);

    const wdays3 = Math.floor(cooldowndistance3 / (24 * 60 * 60 * 1000));
    const whours3 = Math.floor(
      (cooldowndistance3 % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
    );
    const wminutes3 = Math.floor((cooldowndistance3 % (60 * 60 * 1000)) / (1000 * 60));
    const wseconds3 = Math.floor((cooldowndistance3 % (60 * 1000)) / 1000);

    if (cooldowndistance3 < 0) {
      // Stop Timer

      clearInterval(interval3.current);
    } else {
      // Update Timer
      setWTimerDays3(wdays3);
      setWTimerHours3(whours3);
      setWTimerMinutes3(wminutes3);
      setWTimerSeconds3(wseconds3);
      setCooldownDistance3(cooldowndistance3);
     
    }
  });
};

useEffect(() => {
  withdrawstartTimer3();
});

// withdraw3 timer end



// timer monthly withdrawal start
// const [wtimermDays, setWTimermDays] = useState();
// const [wtimermHours, setWTimermHours] = useState();
// const [wtimermMinutes, setWTimermMinutes] = useState();
// const [wtimermSeconds, setWTimermSeconds] = useState();
// const [mcooldowndistance, setmCooldownDistance] = useState();



// let interval;

// const mwithdrawstartTimer = () => {


//   const formonth = 1675793742;
 
//   const countDownMonth = new Date(formonth*1000);

  

//   interval = setInterval(() => {
//     const wnow = new Date();

//     const mcooldowndistance = Date.parse(countDownMonth) - Date.parse(wnow);

//     const mwdays = Math.floor(mcooldowndistance / (24 * 60 * 60 * 1000));
//     const mwhours = Math.floor(
//       (mcooldowndistance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
//     );
//     const mwminutes = Math.floor((mcooldowndistance % (60 * 60 * 1000)) / (1000 * 60));
//     const mwseconds = Math.floor((mcooldowndistance % (60 * 1000)) / 1000);

//     if (mcooldowndistance < 0) {
//       // Stop Timer

//       clearInterval(interval.current);
//     } else {
//       // Update Timer
//       setWTimermDays(mwdays);
//       setWTimermHours(mwhours);
//       setWTimermMinutes(mwminutes);
//       setWTimermSeconds(mwseconds);
//       setmCooldownDistance(mcooldowndistance);
     
//     }
//   });
// };

// useEffect(() => {
//   mwithdrawstartTimer();
// });

// monthly withdraw timer end




// start of get usd value of BNB 

  const walletbnb = walletBalance.bnb;
  // const walletdeposit = walletBalance.power;
  // const walletrefbonus = walletBalance.rewards;
  // const walletrebatereward = walletBalance.refreward;
  // const walletyield = walletBalance.beans;
  // const walletwithdrawn = walletBalance.market;

  const bnbprice = response && response.binancecoin.usd;

  const walletbnbinusd =  walletbnb * bnbprice;
  const walletdepositusd =  walletBalance.power * bnbprice;
  const walletrefbonususd =  walletBalance.rewards * bnbprice;
  const walletrebaterewardusd =  walletBalance.refreward * bnbprice;
  const walletyieldusd =  walletBalance.beans * bnbprice;
  const walletwithdrawnusd =  walletBalance.market * bnbprice;
  const walletlongyieldusd =  walletBalance.longyield * bnbprice;
  const walletlongdepositusd =  walletBalance.longdeposit * bnbprice;
  const walletshortdepositusd =  (walletBalance.power * bnbprice) - (walletBalance.longdeposit * bnbprice);
  const walletshortdepositbnb = walletBalance.power - walletBalance.longdeposit;

  // const inputbnbinusd = bakeBNB * bnbprice;

// end of get usd value of coin 

// start of get total reward to withdraw 

// const totalrewardtowithdraw =  walletrefbonususd + walletrebaterewardusd + walletyieldusd;

// end of get total reward to withdraw 


// toggle short and long days
const [thirtydays, setThirtydays] = React.useState(true);
const [longdays, setLongdays] = React.useState(false);

function handlelongClick() {
  setLongdays(true);
}

// function handleshortClick() {
//   setThirtydays(false);
// }



  return (

    
    <Card  className={classes.card}>
      {loading && <LinearProgress />}

      <CardContent style={{color: "#516913"}}>

      <Box  display= {'flex'}  style={{flexdirection: "row" , justifyContent:"center" }} marginTop={2} marginBottom={2}>

          <Button
            display= {'flex'}
            style={{ boxShadow: "3px 6px 1px 1px #41dff5", color: "#ffffff", flexdirection: "row" , justifyContent:"center", background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))" }}
            size="small"
            variant="elevated"
            onClick={yieldclick}
          > {cooldowndistance ? (
                      <ButtonClock
                        timerDays={wtimerDays}
                        timerHours={wtimerHours}
                        timerMinutes={wtimerMinutes}
                        timerSeconds={wtimerSeconds}
                      />
                    ) : (
                      <> Start Yield</>
                    )}
          </Button>

      </Box>
      <Typography  display= {'flex'}  style={{color: "#41dff5", flexdirection: "row" , justifyContent:"center", fontSize: "17px"}} variant="h5">Click after the timer end</Typography>

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1">Contract:</Typography>
          <Typography variant="h5">{contractBNB} BNB</Typography>
        </Grid> */}
       
        <Box
              sx={{
                p: 2,
                // bgcolor: '#54026b',
                display: 'grid',
                gridTemplateColumns: { md: '1fr' },
                // gridTemplateColumns: { md: '1fr 1fr' },
                gap: 2,
              }}

              style={{color: "#F5BD01"}}
              // borderBottom="6px solid"
            >
         <Item  className={classes.Paper}>
         
         <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {walleticon} </Typography>
       
          <Typography style={{color: "white", margin: "1px"}} variant="h5" >  Wallet Balance </Typography>

          <Divider variant="center" />
        
          <Typography  style={{color: "#41dff5", fontSize: "17px"}} variant="h5">{walletBalance.bnb} BNB </Typography>
          <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ {walletbnbinusd.toFixed(2)}</Typography>

         </Item>


         <Item  className={classes.Paper}> 
       <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {widthrawicon} </Typography>
       
       <Typography  style={{color: "white", margin: "1px"}}  variant="h5">Withdrawn</Typography>
       <Divider variant="center" />
       <Typography  style={{color: "#41dff5", fontSize: "17px"}}  variant="h5">{walletBalance.market} BNB</Typography>
       <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ { walletwithdrawnusd.toFixed(2)}</Typography>

       </Item>


         <Item  className={classes.Paper}>

         <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {tdepositicon} </Typography>
       
         <Typography  style={{color: "white", margin: "1px"}}  variant="h5"> Short Deposits</Typography>
         <Divider variant="center" />
         <Typography  style={{color: "#41dff5", fontSize: "17px"}}  variant="h5"> {walletshortdepositbnb.toFixed(6)} BNB</Typography>
         <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ { walletshortdepositusd.toFixed(2)}</Typography>

         </Item>

         <Item  className={classes.Paper}>

        <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {tdepositicon} </Typography>

        <Typography  style={{color: "white", margin: "1px"}}  variant="h5"> Long Deposits</Typography>
        <Divider variant="center" />
        <Typography  style={{color: "#41dff5", fontSize: "17px"}}  variant="h5">{walletBalance.longdeposit} BNB</Typography>
        <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ { walletlongdepositusd.toFixed(2)}</Typography>

        </Item>

       <Item  className={classes.Paper}> 
       <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {yieldicon} </Typography>
       
       <Typography   style={{color: "white", margin: "1px"}}  variant="h5" >
            Daily 1% Yield(short)
       </Typography>
            <Divider variant="center" />
        <Typography style={{color: "#41dff5", fontSize: "17px"}}  variant="h5" >
              {walletshortdepositbnb ? walletBalance.beans : "0.0000000"} BNB
       </Typography>
       <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ {walletyieldusd.toFixed(2)}</Typography>

       </Item>

       <Item  className={classes.Paper}> 
       <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {yieldicon} </Typography>
       
       <Typography   style={{color: "white", margin: "1px"}}  variant="h5" >
            Daily 1.5% Yield(long)
       </Typography>
            <Divider variant="center" />
        <Typography style={{color: "#41dff5", fontSize: "17px"}}  variant="h5" >
              {walletBalance.longyield} BNB
       </Typography>
       <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ { walletlongyieldusd.toFixed(2)}</Typography>

       </Item>


       <Item  className={classes.Paper}> 
         <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {rbonusicon} </Typography>
       
         <Typography    style={{color: "white", margin: "1px"}}  variant="h5">Total Ref Bonus</Typography>
         <Divider variant="center" />
        <Typography  style={{color: "#41dff5", fontSize: "17px"}}  variant="h5">{walletBalance.rewards} BNB</Typography>
        <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ {walletrefbonususd.toFixed(2)}</Typography>

       </Item>

       <Item  className={classes.Paper}> 
       <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {rebateicon} </Typography>
       
       <Typography   style={{color: "white", margin: "1px"}}  variant="h5">Rebates Reward</Typography>
       <Divider variant="center" />
        <Typography  style={{color: "#41dff5", fontSize: "17px"}}  variant="h5">{walletBalance.refreward} BNB</Typography>
        <Typography  style={{color: "#95dd71", fontWeight: "bolder" }} variant="h6">$ {walletrebaterewardusd.toFixed(2)}</Typography>

       </Item>


       <Item  className={classes.Paper}> 
       <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {refnumbericon} </Typography>
       <Typography  style={{color: "white", margin: "5px"}}  variant="h5">Total Referrals</Typography>
       <Divider variant="center" />
       <Typography  style={{color: "#41dff5"}}  variant="h5">{walletBalance.refcount}</Typography>

       </Item>

       <Item  className={classes.Paper}> 
       <Typography  style={{color: "white", marginTop: "40px"}} variant="h4" > {refbyicon} </Typography>
       
       <Typography  style={{color: "white", margin: "5px"}}  variant="h5">Referred By:</Typography>
       <Divider variant="center" />
       <Typography  style={{color: "#41dff5"}} variant="h5">
        <Address  a= {refadrs} />
      </Typography>

       </Item>
 
        </Box>



        <Box  display= {'flex'}  style={{ flexdirection: "row" , justifyContent:"center" }} marginTop={2} marginBottom={2}>

        <FormControlLabel
           
              style={{ flexdirection: "row" ,  justifyContent:"center"}}
              sx={{
                display: 'flex',
              }}
              control={
                <Switch
                  checked={thirtydays}
                  onChange={() => setThirtydays(!thirtydays)}
                  onClick= {handlelongClick}
                  name="3 days"
                  style={{color:"white"}}
                />
              }
              label="Short"
          />

          <FormControlLabel
            style={{flexdirection: "row" , justifyContent:"center"}}
              sx={{
                display: 'flex-end',
              }}
              control={
                <Switch
                  checked={longdays}
                  onChange={() => setLongdays(!longdays)}
                  // onClick= {handleshortClick}
                  name="100 days"
                  style={{color:"white"}}
                />
              }
              label="Long"
          />

        </Box>

        

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
         <Div>

          <Typography variant="body1">Deposited:</Typography>
          <Typography variant="h5">{walletBalance.power} BNB</Typography>

         </Div>
        </Grid> */}
        
        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1">Referral Reward:</Typography>
          <Typography variant="h5">{walletBalance.rewards} BNB</Typography>
        </Grid> */}

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1">Indirect Reward:</Typography>
          <Typography variant="h5">{walletBalance.refreward} BNB</Typography>
        </Grid> */}

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1"> Indirect Reward got:</Typography>
          <Typography variant="h5">{walletBalance.allseedreward} BNB</Typography>
        </Grid> */}

        {/* <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Typography variant="body1" >
              Your Yield:
            </Typography>
            <Typography variant="h5" >
              {walletBalance.beans} BNB
            </Typography>
          </Grid> */}

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1">Your Referrals:</Typography>
          <Typography variant="h5">{walletBalance.refcount}</Typography>
        </Grid> */}


        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1">Referred By:</Typography>
          <Address style={{ fontFamily: 'monospace', color: 'green' }} a={refadrs} />
          
        </Grid> */}


        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="body1">Withdrawn:</Typography>
          <Typography variant="h5">{walletBalance.market} BNB</Typography>
        </Grid> */}
       


        <Box paddingTop={2} paddingBottom={2} >

          <Box>
            <PriceInput
              
              max={+walletBalance.bnb}
              value={bakeBNB}
              onChange={(value) => onUpdateBakeBNB(value)}
              text= "0.00"
            />
            {/* <FormHelperText id="component-helper-text"  style={{color: "white", justifyContent: 'center', textAlign: 'center',}}>
               USD VALUE: $ {inputbnbinusd}
           </FormHelperText> */}
          </Box>
          {/* <Box marginTop={3} marginBottom={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={bake}
              style={{color: "white"}}
              mt={3}
            >

                   {distance? <ButtonClock
                    timerDays={timerDays}
                    timerHours={timerHours}
                    timerMinutes={timerMinutes}
                    timerSeconds={timerSeconds}
                  /> : <span>Deposit</span>}
                  
            </Button>

          </Box> */}

          <Box marginTop={2} marginBottom={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={thirtydays? investshort : investlong}
              color="secondary"
              style={{ background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))"}}
              mt={3}
              > 
                {loading1 && <CircularProgress />} &nbsp;
                {depositicon}{thirtydays ? "Deposit(Short)" : "Deposit(Long)"}
              </Button>

          </Box>

          <Divider />


          {/* <Typography marginTop="auto" textAlign="center" variant="body1" fontSize="1.2rem" style={{color: "#95dd71" }}>
             $ {totalrewardtowithdraw.toFixed(2)}
          </Typography> */}

          {/* <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Typography variant="body1" fontWeight="bolder">
              Your Yield
            </Typography>
            <Typography variant="h5" fontWeight="bolder">
              {walletBalance.beans} BNB
            </Typography>
          </Grid> */}

          <ButtonContainer container>

            {/* <Grid item flexGrow={1} marginRight={1} marginTop={3}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                
                onClick={reBake}
              >
                Compound
              </Button>
            </Grid> */}


            {/* <Grid item flexGrow={1} marginLeft={1} marginTop={3}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                
                onClick={eatBeans}
              >


                    {cooldowndistance ? <ButtonClock
                    timerDays={wtimerDays}
                    timerHours={wtimerHours}
                    timerMinutes={wtimerMinutes}
                    timerSeconds={wtimerSeconds}
                  /> : <span>Withdraw</span>}
               
              </Button>
            </Grid> */}
          </ButtonContainer>

          <Typography marginTop={2} textAlign="center" variant="body1" fontSize="1.2rem" style={{color: "white" }}>
              (Ref Bonus + Rebate)
          </Typography>

          <Box marginTop={3} marginBottom={2}>


          {/* { address &&

                <Button
                  variant="contained"
                  color="secondary"
                  style={{background: "linear-gradient(#54026b, #54026b)"}}
                  fullWidth
                  onClick={mcooldowndistance ? null : (eatBeans)}
                >
                  {mcooldowndistance ? (
                    <ButtonClock
                      timerDays={wtimermDays}
                      timerHours={wtimermHours}
                      timerMinutes={wtimermMinutes}
                      timerSeconds={wtimermSeconds}
                    />
                  ) : (
                    <>{withdrawicon}Withdraw</>
                  )}
                </Button>  
            } */}
                
            { address &&
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))"}}
                  fullWidth
                  onClick={(eatBeans)}
                >
                    
                    <>  {loading2 && <CircularProgress />} &nbsp; {withdrawicon}Withdraw</>
                  
                </Button>

                }

              </Box>

                <Box marginTop={3} marginBottom={2}>

                { address && thirtydays &&
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))"}}
                  fullWidth
                  onClick={cooldowndistance2 ? null : (eatAllShort)}
                >
                  { cooldowndistance2 ? (
                    <ButtonClock
                      timerDays={wtimerDays2}
                      timerHours={wtimerHours2}
                      timerMinutes={wtimerMinutes2}
                      timerSeconds={wtimerSeconds2}
                    />
                  ) : (
                    <> {loading3 && <CircularProgress />} &nbsp; {withdrawicon} Withdraw All(Short) </>
                  )}
                </Button>

                }


              {/* <Typography marginTop="auto" textAlign="center" variant="body1" fontWeight="bolder">
              (Claim Timer)
            </Typography> */}

            </Box>

            <Box marginTop={3} marginBottom={0}>

                  { address && longdays &&
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ background: "linear-gradient(rgb(0 0 0 / 14%), rgb(84, 2, 107))"}}
                    fullWidth
                    onClick={cooldowndistance3 ? null : (eatAllLong)}
                  >
                    { cooldowndistance3 && walletlongdepositusd ? (
                      <ButtonClock
                        timerDays={wtimerDays3}
                        timerHours={wtimerHours3}
                        timerMinutes={wtimerMinutes3}
                        timerSeconds={wtimerSeconds3}
                      />
                    ) : (
                      <> {loading4 && <CircularProgress />} &nbsp; {withdrawicon} Withdraw All(Long) </>
                    )}
                  </Button>

                  }

                  {/* <Typography marginTop="auto" textAlign="center" variant="body1" fontWeight="bolder">
                  (Claim Timer)
                  </Typography> */}

             </Box>

        </Box>  

      </CardContent>

    </Card>
    
  );
}
