import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cardimage1Url from "../../assets/frame2.webp";
import { styled } from "@mui/system";

// const CardWrapper = styled(Card)({
//   background: "#022e6b",
//   marginBottom: 24,
// });

const useStyles = makeStyles({
  card: {
    // check index.css for  backgroundImage: `url(${process.env.PUBLIC_URL}/src/assets/frame2.webp)`,
    backgroundSize: 'cover',
    marginBottom: 26,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 100,
    backgroundImage: `url(${cardimage1Url})`,
    backgroundPositionX: "center",
    backgroundPositionY: "crnter",
    // boxShadow: "none",
  }

});


export default function NutritionFacts() {

  const classes = useStyles();

  return (
    <Card  className={classes.card}>
      <CardContent style={{fontSize: "18px"}}>
        <Typography style={{color: "#afd5f0", fontSize: "20px"}} variant="h5" paddingTop={1} paddingBottom={1}>
        Other Info:
        </Typography>
        <Box  paddingTop={10} paddingBottom={8}>
         
            <Grid container  justifyContent="space-between">
              <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

              - Community Own Project
               
              </Typography>

              <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

               - Decentralized system.
 
              </Typography>
              <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

               - Smart Chain BNB contract.

              </Typography>
              <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

               - wallet supported (metamask, trustwallet).

                </Typography>
                <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

                - Enjoy daily profit from Yielding BNB.

                </Typography>
                <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

                - Direct referal + Rebates from 1st lvl to 10th lvl generation.

                </Typography>
                <Typography style={{color: "#afd5f0"}} variant="body1" gutterBottom>

                - Real time withrawal NO need for approval.

                </Typography>

              
            </Grid>
         
        </Box>
      </CardContent>
    </Card>
  );
}
